// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://awbrvqrtqxwomnevipdt.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImF3YnJ2cXJ0cXh3b21uZXZpcGR0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAyNDc2OTksImV4cCI6MjA1NTgyMzY5OX0.hSk2QO1cLGr99XAK34NZpBlJw2uQyxLkuIGlece1Mow";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);